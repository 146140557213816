.cursor-pointer {
  cursor: pointer;
}

.text-dim-gray {
  color: $dim-gray;
}

.d-contents {
  display: contents;
}

.upholstery-glassmorphism {
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(0.5rem);
}
