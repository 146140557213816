.bg-form-container {
  background-color: #8C8C8C !important;
}

.bg-input {
  background-color: transparent !important;
}

.bg-input:focus {
  background-color: transparent !important;
}

input, 
input::placeholder, 
textarea::placeholder, 
select::placeholder {
  color: white !important;
}

textarea {
  height: 150px;
  color: white !important;
}

.select-control {
  height: 44px;
}

.fs-form-btn {
  font-size: 1rem !important;
}