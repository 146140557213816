footer {
}

.logo-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-instance {
  width: 42%;
  height: auto;
  max-width: 100%;
}

.logo-instance-lonely {
  width: 12%;
  height: auto;
  max-width: 100%;
}

.copyright-label {
  position: absolute;
  bottom: 0;
  display: block;
  font-size: calc($font-size-xs * 0.85);
  text-transform: uppercase;
}

.footer-sections {
  list-style: none;
}

.label {
  font-family: $font-family-title;
}

.social-media-container {
  float: right;
}

.social-media-icon {
  margin: 0 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
