@include media-breakpoint-up(md) {
  .simple-slider {
    height: 550px;
    z-index: 0;
    overflow: hidden;
  }

  .container-images {
    height: 550px;
  }

  .slider--first_banner-description-content {
    position: absolute;
    top: 45%;
    left: 11%;
  }
}

