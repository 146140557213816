.bg-project-description {
  background-color: rgba($dim-gray, 0.13);
}

.img--description {
  height: 100%;
  background-size: cover;
  background-position: center right;
}

.line-up {
  border-top: solid 0.25rem;
  border-color: black;
}

.figure {
  font-size: 2.5rem;
  font-weight: bold;
}
