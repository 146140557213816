@include media-breakpoint-down(md) {
  .image-overshadow {
    filter: brightness(0.5);
    height: 100%;
  }

  .slider--first_banner-description-paragraph {
    font-size: 1.25rem;
  }
}
