.label {
  white-space: pre;
}

.parent {
  display: grid !important;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 670px repeat(2, 325px);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.section {
  background-size: cover;
  background-position: center;
}

.sec-1 {
  grid-area: 1 / 1 / 2 / 3;
}
.sec-2 {
  grid-area: 2 / 1 / 3 / 2;
}
.sec-3 {
  grid-area: 2 / 2 / 3 / 3;
}
.sec-4 {
  grid-area: 3 / 1 / 4 / 2;
}
.sec-5 {
  grid-area: 3 / 2 / 4 / 3;
}
