.description-service {
  height: 300px;
}

.product-container {
  background-color: rgba($dim-gray, 0.13);
}

.subtitle-color {
  color: $battleship-gray;
}