@include media-breakpoint-up(md) {
  .video-container {
    height: 400px;
  }

  .video-section-container, .video-section-sub-container  {
    height: fit-content;
    padding: 10px;
  }
}

