.privacy-policy-checkbox {
  a {
    color: inherit;
    font-weight: $font-weight-bold;
  }
}

%field-check {
  top: 2px;
  width: 15px;
  height: 15px;
  appearance: none;
  position: relative;
  border: solid 1px white;
  cursor: pointer;
}

%field-check-checked {
  content: "✓";
  position: absolute;
  width: 16px;
  height: 17px;
  top: -0.9px;
  left: -1.063px;
  text-align: center;
  font-size: 13px;
  color: $white;
  background: $black;
}

.step-form--field-check {
  @extend %field-check;
  &:checked::before {
    @extend %field-check-checked;
  }
}

.step-form--field-check--controlled {
  @extend %field-check;
}

.step-form--field-check--controlled-checked {
  &::before {
    @extend %field-check-checked;
  }
}

.underlined-private-policy {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
