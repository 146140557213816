@font-face {
  font-family: "Futura";
  src: url("Futura-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("Futura-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("Futura-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
