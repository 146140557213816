.prev-slick-arrow {
  position: absolute;
  top: -10px;
  left: -50px;
}

.next-slick-arrow {
  position: absolute;
  top: -10px;
}

.default {
  fill: $dim-gray;
  opacity: calc(0.40);
  height: 2em !important;
  // filter: invert(50%) sepia(0%) saturate(00%) hue-rotate(0deg);
}

.selected {
  fill:#8C8C8C !important;
}