.simple-slider {
  height: 500px;
  z-index: 0;
  overflow: hidden;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 740px;
}

.slick-dots {
  position: absolute;
  bottom: 40px; 
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  color: white;
}

.slick-dots li {
  margin: 0 15px !important; 
  margin-bottom: 20px !important;
}

.slick-dots li button::before {
  font-family: "slick";
  font-size: 14px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 255, 255) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button {
  width: 20px; 
  height: 20px;
  position: absolute;
  bottom: 40px;
  border-radius: 50%;
  border: none;
  background-color: #ccc; /* Color de los puntos inactivos */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.container-images {
  height: 500px;
  position: relative;
}

.image-overshadow {
  filter: brightness(0.5);
  height: 110%;
}

.slider--first_banner-description-content {
  position: absolute;
  top: 40%;
  left: 11%;
}

.slider--banner_more-information-btn {
  padding: 6px 24px !important;
  border-radius: 30px !important;
  font-size: 1.125rem !important;
}

.slider--banner_more-information-btn:hover {
  background-color: #595959 !important;
  color: white !important;
  border: 1px solid #595959 !important;
}