header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-static {
  position: absolute;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  cursor: pointer;

  img {
    width: 64%;
  }
}

.upholstery-glassmorphism {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
  background: hsla(0,0%,100%,.25);
  padding: 1rem 1.5rem;
}
