.home-slide {
  // position: relative;
  background-color: $skeleton-background;
  background-position: center center;
  background-size: cover;
  padding-top: $header-height;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 99%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.home-slide__content {
  position: relative;
  z-index: 2;
}

.slide-content {
  margin: 2.5rem 0 0 2.5rem;
}

.slide-content__description {
  white-space: pre-wrap;
}
