@include media-breakpoint-up(md) {
  .design-sector-img {
    width: 600px;
    height: 300px;
  }

  .slider-sectors {
    .slick-slide {
      width: 430px !important;
      margin-left: 30px;
    }

    .slick-list {
      height: 800px;
    }
  }
}
