h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
}

p,
pre,
a,
label,
li,
span {
  font-family: $font-family-base;
}

sup {
  top: -0.5rem;
}
